<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'chart',
  props: {
    title: String,
    subTitle: String,
    fileName: String,
    ratingType: String,
    keibajoName: String,
    raceBango: String,
  },
  data () {
    return {
      data: {
        labels: ["3位以内", "6位以内", "9位以内", "12位以内", "15位以内"],
        datasets: [
        ]
      },
      options: {
        legend: {
          labels: {
            fontColor: 'rgba(255, 255, 255, 1)'
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              min:0,
              max:1
            },
            scaleLabel: {
              display: true,
              labelString: '想定最終位置'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              max:1
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.horses = JSON.parse(JSON.stringify(require(`@/data/${this.keibajoName}-${Number(this.raceBango)}/${this.fileName}`)))
    const colors = [
      'rgba(255, 0, 0, 1)',
      'rgba(0, 255, 0, 1)',
      'rgba(255, 255, 0, 1)',
      'rgba(0, 0, 255, 1)',
      'rgba(255, 0, 255, 1)',
      'rgba(0, 255, 255, 1)',
      'rgba(255, 255, 255, 1)',
      'rgba(128, 0, 0, 1)',
      'rgba(0, 128, 0, 1)',
      'rgba(128, 128, 0, 1)',
      'rgba(0, 0, 128, 1)',
      'rgba(128, 0, 128, 1)',
      'rgba(0, 128, 128, 1)',
      'rgba(128, 128, 128, 1)',
      'rgba(64, 0, 0, 1)',
      'rgba(0, 64, 0, 1)',
      'rgba(64, 64, 0, 1)',
      'rgba(0, 0, 64, 1)',
      'rgba(64, 0, 64, 1)',
      'rgba(0, 64, 64, 1)',
      'rgba(64, 64, 64, 1)',
    ]
    // this.horses = this.horses.map(data => {
    //   data.score = data.score / data.score2
    //   return data;
    // })
    for (const horse of this.horses) {
      horse.score = Number(horse.score)
      horse.score1 = Number(horse.score1)
      horse.score2 = Number(horse.score2)
      horse.score3 = Number(horse.score3)
      horse.score4 = Number(horse.score4)
      horse.score5 = Number(horse.score5)
      // horse.score1 = 0;
    }
    this.horses = this.horses.sort((d1,d2) => (d2.score - d1.score))
    this.data.labels = this.horses.map(data => data.bamei);
    const maxUmaban = Math.max(...this.horses.map(data => Number(data.umaban)))
    const max = Math.max(...[Math.max(...this.horses.map(data => data.score)),
    Math.max(...this.horses.map(data => data.score1)),
    Math.max(...this.horses.map(data => data.score2)),
    Math.max(...this.horses.map(data => data.score3)),
    Math.max(...this.horses.map(data => data.score4))]);
    // this.options.scales.yAxes[0].ticks.max = max;

    this.data.datasets = [
      {
        label: "1-3位",
        data: this.horses.map(data => Number(data.score)),
        backgroundColor: colors[0]
      },
      {
        label: "4-6位",
        data: this.horses.map(data => data.score1),
        backgroundColor: colors[1]
      }]

      if (maxUmaban >= 7){
        this.data.datasets.push(
        {
          label: "7-9位",
          data: this.horses.map(data => data.score2),
          backgroundColor: colors[2]
        });
      }
      if (maxUmaban >= 10){
        this.data.datasets.push(
        {
          label: "10-12位",
          data: this.horses.map(data => data.score3),
          backgroundColor: colors[3]
        });
      }
      if (maxUmaban >= 13){
        this.data.datasets.push(
        {
          label: "13-15位",
          data: this.horses.map(data => data.score4),
          backgroundColor: colors[4]
        });
      }
      if (maxUmaban >= 16){
        this.data.datasets.push(
        {
          label: "16-18位",
          data: this.horses.map(data => data.score5),
          backgroundColor: colors[5]
        })
      }
    this.renderChart(this.data, this.options)
  }
}
</script>
