<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="card-category"></h5>
                <h2 class="card-title">
                  <!-- <a target="_blank" :href="`https://race.netkeiba.com/race/shutuba.html?race_id=${this.$route.params.id}`">  -->
                    {{this.$route.params.date}} {{keibajo_name_ja[this.$route.params.keibajo_name]}} 第{{this.$route.params.race_no.padStart(2,0)}}レース
                  <!-- </a>&nbsp;<i class="tim-icons icon-zoom-split"></i> -->
                </h2>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-sm-12" >
        <v-btn v-for="item in schedules" :key="item" @click="changeRace('keibajo',item.key)">{{item.name}}</v-btn>
      </div>
      <div class="col-sm-12">
        <v-btn  v-for="item in raceNums" :key="item" @click="changeRace('raceNum',item)">{{item}}R</v-btn>
      </div>
    </div> -->
    <div  class="row">
      <!-- <div class="d-lg-none col-3">
      </div> -->
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="card-category">3着以内に入る可能性が高い順に表示。指数や競馬場、過去レコードから算出した最終見解</h5>
                <h2 class="card-title">ユーミィの直前AI予想</h2>
                <p><b>各馬の着順を<span style="color:red;">約束するものではありません。</span>馬券の購入は<span style="color:red;">自己責任で</span>お願いいたします。</b></p>
                <p><b><span style="color:red;">3着以内に入る可能性が高い順に表示。</span>スコアが高い馬が早くゴールするわけではありません。</b></p>
                <br/>
                <p><b>項目名をクリックすると、ソート順を変更できます。</b></p>
              </div>
            </div>
          </template>
          <!--class="table-responsive" ↓これを設定するとスクロールバーが出るよ-->
          <div class="col-12" style="min-height:400px;background-image: url(/img/tachie-smile.png);background-repeat: no-repeat;background-position: right bottom;background-size:200px;">
            <ai-rating file-name="predict.json" :date="this.$route.params.date" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></ai-rating>
          </div>
          <!-- <div class="col-12 ma-0 pa-0" align="center">
            <h5 class="card-category">[PR]</h5>
            <iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=48&l=ur1&category=monthlydealevent&banner=10DEP8HAD0S6SVM25D82&f=ifr&linkID=25ce122e59597f472de31ab714348daa&t=ummy-22&tracking_id=ummy-22" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
          </div>
          <div class="col-12">
            <h3 class="mt-5">想定着差(試験運用機能)</h3>
            <p><b>右側ほど、早くゴールする予想。AI予想上、どれくらい実力が拮抗しているかの目安です。</b></p>
            <chart :height="100" file-name="predict.json" :date="this.$route.params.date" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></chart>
          </div> -->
          <!-- <div class="col-12">
            <h3 class="mt-5">ユーミィちゃんAIに競馬を教えてあげる</h3>
            <p><b>この予想はいかがでしたか？ユーミイAIが見れていなさそうなポイントや要素、アドバイスなどあれば、コメントでご連絡ください！<br/>次回の学習に活用します。<br/>的中報告もお待ちしております！</b></p>
            <disqus shortname='yumiinojing-ma-yu-xiang-aikai-fa-shi' />
            <p><b>メールアドレスは公開されません。ゲストコメントは管理者承認後、表示されます。</b></p>
          </div> -->
          <!-- <div class="col-12">
          </div> -->
        </card>
      </div>
      <!-- <div class="d-lg-none col-3">
        <v-row justify="start" align-content="end">
          <v-col>
            <img height="400px" src="/img/tachie-normal.png"/>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </v-col>
        </v-row>
      </div> -->
    </div>
    <!-- <div class="row">

        <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-12" align="center">
                <h5 class="card-category">[PR]</h5>
                <div class="col-3 float-right">
                  <p><a href="https://www.amazon.co.jp/%E3%81%B0%E3%81%8F%E3%81%B0%E3%81%8F-%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93-%E3%82%A4%E3%83%96%E3%83%8B%E3%83%B3%E3%82%B0KC-%E9%AB%98%E6%A9%8B-%E3%82%B3%E3%82%A6/dp/406354656X?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=237JQK85RPDOJ&keywords=%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93&qid=1653402382&sprefix=%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93%2Caps%2C155&sr=8-1&linkCode=li2&tag=ummy-22&linkId=c3d7fde0162751053800849a4ecd7799&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=406354656X&Format= _SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=ummy-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=ummy-22&language=ja_JP&l=li2&o=9&a=406354656X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></p> <p><a href="https://www.amazon.co.jp/%E3%81%B0%E3%81%8F%E3%81%B0%E3%81%8F-%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93-%E3%82%A4%E3%83%96%E3%83%8B%E3%83%B3%E3%82%B0KC-%E9%AB%98%E6%A9%8B-%E3%82%B3%E3%82%A6/dp/406354656X?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=237JQK85RPDOJ&keywords=%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93&qid=1653402382&sprefix=%E3%83%90%E3%82%AF%E3%83%81%E3%81%94%E3%81%AF%E3%82%93%2Caps%2C155&sr=8-1&linkCode=li2&tag=ummy-22&linkId=c3d7fde0162751053800849a4ecd7799&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow">ばくばく!バクチごはん(1) </a></p>
                </div>
                <div class="col-3 float-right">
                  <p><a href="https://www.amazon.co.jp/AI%E7%AB%B6%E9%A6%AC-%E4%BA%BA%E5%B7%A5%E7%9F%A5%E8%83%BD%E3%81%AF%E9%A6%AC%E5%88%B8%E3%82%92%E5%88%B6%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%8B%EF%BC%9F-%E5%9F%8E%E5%B4%8E%E5%93%B2-ebook/dp/B08HZ81YC4?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=32G42KESRO2WW&keywords=AI%E7%AB%B6%E9%A6%AC&qid=1653402983&sprefix=ai%E7%AB%B6%E9%A6%AC%2Caps%2C190&sr=8-1&linkCode=li2&tag=ummy-22&linkId=406665239234cd20c1931c753bceb1c0&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08HZ81YC4&Format= _SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=ummy-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=ummy-22&language=ja_JP&l=li2&o=9&a=B08HZ81YC4" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></p> <p><a href="https://www.amazon.co.jp/AI%E7%AB%B6%E9%A6%AC-%E4%BA%BA%E5%B7%A5%E7%9F%A5%E8%83%BD%E3%81%AF%E9%A6%AC%E5%88%B8%E3%82%92%E5%88%B6%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E3%81%8B%EF%BC%9F-%E5%9F%8E%E5%B4%8E%E5%93%B2-ebook/dp/B08HZ81YC4?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=32G42KESRO2WW&keywords=AI%E7%AB%B6%E9%A6%AC&qid=1653402983&sprefix=ai%E7%AB%B6%E9%A6%AC%2Caps%2C190&sr=8-1&linkCode=li2&tag=ummy-22&linkId=406665239234cd20c1931c753bceb1c0&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow">AI競馬 人工知能は馬券を制することができるか？</a></p>
                </div>
                <div class="col-3 float-right">
                  <p><a href="https://www.amazon.co.jp/%E7%AB%B6%E9%A6%AC%E3%81%A7%E3%81%94%E3%81%AF%E3%82%93-%E6%80%9D%E3%81%84%E5%87%BA%E9%A3%9F%E5%A0%82%E3%82%B3%E3%83%9F%E3%83%83%E3%82%AF%E3%82%B9-%E8%B0%B7%E5%8F%A3%E3%81%82%E3%81%95%E3%81%BF-ebook/dp/B09RDSYBC8?_encoding=UTF8&qid=1653403159&sr=8-1&linkCode=li2&tag=ummy-22&linkId=264e33f3a3d674679fae3f8306dcf8b3&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09RDSYBC8&Format= _SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=ummy-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=ummy-22&language=ja_JP&l=li2&o=9&a=B09RDSYBC8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></p> <p><a href="https://www.amazon.co.jp/%E7%AB%B6%E9%A6%AC%E3%81%A7%E3%81%94%E3%81%AF%E3%82%93-%E6%80%9D%E3%81%84%E5%87%BA%E9%A3%9F%E5%A0%82%E3%82%B3%E3%83%9F%E3%83%83%E3%82%AF%E3%82%B9-%E8%B0%B7%E5%8F%A3%E3%81%82%E3%81%95%E3%81%BF-ebook/dp/B09RDSYBC8?_encoding=UTF8&qid=1653403159&sr=8-1&linkCode=li2&tag=ummy-22&linkId=264e33f3a3d674679fae3f8306dcf8b3&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow">競馬でごはん </a></p>
                </div>
                <div class="col-3 float-right">
                  <p><a href="https://www.amazon.co.jp/%EF%BC%BB%E7%AC%AC3%E7%89%88%EF%BC%BDPython%E6%A9%9F%E6%A2%B0%E5%AD%A6%E7%BF%92%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0-%E9%81%94%E4%BA%BA%E3%83%87%E3%83%BC%E3%82%BF%E3%82%B5%E3%82%A4%E3%82%A8%E3%83%B3%E3%83%86%E3%82%A3%E3%82%B9%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E7%90%86%E8%AB%96%E3%81%A8%E5%AE%9F%E8%B7%B5-impress-top-gear%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA-ebook/dp/B08LYWFPQ9?_encoding=UTF8&qid=1653403252&sr=8-5&linkCode=li2&tag=ummy-22&linkId=f467146f0f23a27a20971e6662928640&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08LYWFPQ9&Format= _SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=ummy-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=ummy-22&language=ja_JP&l=li2&o=9&a=B08LYWFPQ9" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /></p> <p><a href="https://www.amazon.co.jp/%EF%BC%BB%E7%AC%AC3%E7%89%88%EF%BC%BDPython%E6%A9%9F%E6%A2%B0%E5%AD%A6%E7%BF%92%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0-%E9%81%94%E4%BA%BA%E3%83%87%E3%83%BC%E3%82%BF%E3%82%B5%E3%82%A4%E3%82%A8%E3%83%B3%E3%83%86%E3%82%A3%E3%82%B9%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E7%90%86%E8%AB%96%E3%81%A8%E5%AE%9F%E8%B7%B5-impress-top-gear%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA-ebook/dp/B08LYWFPQ9?_encoding=UTF8&qid=1653403252&sr=8-5&linkCode=li2&tag=ummy-22&linkId=f467146f0f23a27a20971e6662928640&language=ja_JP&ref_=as_li_ss_il" target="_blank" rel="nofollow">［第3版］Python機械学習プログラミング</a></p>
                </div>
              </div>
            </div>
          </template>
        </card>
        </div>
    </div> -->
    <div class="row">
      <!-- <div class="col-3">
      </div> -->
      <div class="col-12" >
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-12">
                <h5 class="card-category">指数や競馬場、過去レコードから算出した予想。馬体重は前レースのものを維持していると仮定。天候・馬場状態などは推測値。</h5>
                <h2 class="card-title">ユーミィの<span style="color:red;">前日</span>AI予想</h2>
                <div dark style="color:white;">この予想は<span style="color:red;">前日</span>のデータから予想したものです。パドックや当日の馬体重等のデータを予測に組み込んでいません。</div>
                <p><b>各馬の着順を<span style="color:red;">約束するものではありません。</span>馬券の購入は<span style="color:red;">自己責任で</span>お願いいたします。</b></p>
                <p><b><span style="color:red;">3着以内に入る可能性が高い順に表示。</span>スコアが高い馬が早くゴールするわけではありません。</b></p>
                <br/>
                <p><b>項目名をクリックすると、ソート順を変更できます。</b></p>
              </div>
            </div>
          </template>
          <!--class="table-responsive" ↓これを設定するとスクロールバーが出るよ-->
          <div class="col-12" style="min-height:400px;background-image: url(/img/tachie-normal.png);background-repeat: no-repeat;background-position: right bottom;background-size:200px;">
            <ai-rating file-name="zenjitsu_predict.json" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></ai-rating>
          </div>
          <!-- <div class="col-12 ma-0 pa-0" align="center">
            <h5 class="card-category">[PR]</h5>
            <iframe src="https://rcm-fe.amazon-adsystem.com/e/cm?o=9&p=48&l=ur1&category=monthlydealevent&banner=10DEP8HAD0S6SVM25D82&f=ifr&linkID=25ce122e59597f472de31ab714348daa&t=ummy-22&tracking_id=ummy-22" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"></iframe>
          </div>
          <div class="col-12">
            <h3 class="mt-5">想定着差(試験運用機能)</h3>
            <p><b>右側ほど、早くゴールする予想。AI予想上、どれくらい実力が拮抗しているかの目安です。</b></p>
            <div align="center">
              <chart data-server-rendered="true" :height="100" file-name="zenjitsu_predict.json" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></chart>
            </div>
          </div> -->
        </card>
      </div>
      <!-- <v-row justify="start" align-content="end">
        <v-col>
          <img height="400px" src="/img/tachie-smile.png"/>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </v-col>
      </v-row> -->
    </div>
<!--
    <div class="row">
      <main-shisu title="出走馬・指数" sub-title="出走馬別の指数" file-name="horseRating.json" rating-type="horse" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></main-shisu>
    </div>
    <div class="row">
      <main-shisu title="騎手・指数" sub-title="騎手別の指数" file-name="kishuRating.json" rating-type="jockey" :keibajo-name="this.$route.params.keibajo_name" :race-bango="this.$route.params.race_no"></main-shisu>
    </div>
-->
  </div>
</template>
<script>

import { Disqus } from 'vue-disqus'
  import AiRating from './Dashboard/AiRating';
  import MainShisu from './Dashboard/MainShisu';
  import { BaseAlert } from '@/components';
  import Chart from './Chart';
  import Chart2 from './Chart2';
  export default {
    components: {
      AiRating,
      MainShisu,
      BaseAlert,
      Chart,
      Chart2,
      Disqus
    },
    data() {
      return {
        "schedules": [
          {name:"京都",key : "kyoto"},
          {name:"東京",key : "tokyo"},
          {name:"新潟",key : "niigata"},
        ],
        "raceNums": [
          1,2,3,4,5,6,7,8,9,10,11,12
        ],
        "keibajo_name_ja": {
          "nakayama": "中山",
          "tokyo": "東京",
          "fukushima": "福島",
          "hanshin": "阪神",
          "niigata": "新潟",
          "chukyo": "中京",
          "kyoto": "京都",
          "kawasaki":"川崎",
          "ooi":"大井",
          "monbetsu":"門別",
          "hakodate": "函館",
          "kokura": "小倉",
          "sapporo": "札幌",
          "urawa": "浦和",
          "funabashi": "船橋",
          "saga":"佐賀",
          "sonoda":"園田",
          "himeji":"姫路",
          "kasamatsu":"笠松",
          "morioka":"盛岡",
          "mizusawa":"水沢",
          "kanazawa":"金沢",
          "nagoya":"名古屋",
          "kouchi":"高知"
        },
        "showChokuzen": true,
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    watch:{
      "$route.params.race_no": function() {
        // this.kaisaiDate = this.$route.params.date
        // this.keibajoName = this.$route.params.keibajo_name
        // this.race_bango = this.$route.params.race_no
      }
    },
    methods: {
      changeRace(key, val) {
        console.log(this.$route.params.keibajo_name);
        if(key == "keibajo") {
          if (this.keibajo_name_ja[val] !== undefined) {
            const raceNum = this.$route.params.race_no;
            this.$router.push({ path: `../${val}/${raceNum}`})
          }
        }
        if(key == "raceNum") {
          if (1 <= Number(val) &&  Number(val) <= 12) {
            const keibajo = this.$route.params.keibajo_name;
            this.$router.push({ path: `../${keibajo}/${val}`})
          }
        }
      }
    },
    mounted() {
      let schedule = JSON.parse(JSON.stringify(require(`@/data/schedule.json`)));
      schedule = schedule.sort((d1,d2) => {
        return d1.path > d2.path ? 1 : -1;
      });
      this.schedules = Array.from(new Set(schedule.map(d => d.path.split("/")[0]))).map(d => ({name:this.keibajo_name_ja[d],key:d})).sort((d1,d2) => d1.name - d2.name);
      this.kaisai_nen = schedule[0].kaisai_nen;
      this.kaisai_tsuki = schedule[0].kaisai_tsuki;
      this.kaisai_bi = schedule[0].kaisai_bi;
      // try {
      //   const predict = JSON.parse(JSON.stringify(require(`@/data/${this.keibajoName}-${Number(this.raceBango)}/predict.json`)))
      //   if (!predict.length > 0) {
      //     this.showChokuzen = false;
      //   }
      // } catch (ex) {
      //   this.showChokuzen = false;
      // }
      document.title = `${this.kaisai_nen}年${this.kaisai_tsuki}月${this.kaisai_bi}日 ${this.keibajo_name_ja[this.$route.params.keibajo_name]} 第${this.$route.params.race_no.padStart(2,0)}レース - 🏇ユーミィの競馬AI予想🏇`;
    }
  };
</script>
<style>
</style>
