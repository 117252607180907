<template>
  <div class="col-12">
    <card type="chart">
      <template slot="header">
        <div class="row">
          <div class="col-sm-12" :class="'text-left'">
            <h5 class="card-category">{{ subTitle }}</h5>
            <h2 class="card-title">{{ title }}</h2>
            <br/>
            <p><b>項目名をクリックすると、ソート順を変更できます。</b></p>
          </div>
          <!-- <div class="col-sm-6"> -->
            <!-- <div
              class="btn-group btn-group-toggle"
              :class="'float-right'"
              data-toggle="buttons"
            >
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: true }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                主要指数
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                競馬場
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                周回方向
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                芝・距離別
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                ダート・距離別
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                馬場状態別
              </label>
              <label
                class="btn btn-sm btn-primary btn-simple"
                :class="{ active: false }"
                id="1"
              >
                <input
                  type="radio"
                  name="options"
                  autocomplete="off"
                  :checked="true"
                />
                天候別
              </label>
            </div> -->
          <!-- </div> -->
        </div>
      </template>
      <!--class="table-responsive" ↓これを設定するとスクロールバーが出るよ-->
      <div>
        <v-data-table
          dark
          :headers="headers"
          :items="horses"
          :items-per-page="100000"
          :hide-default-footer="true"
        >
        </v-data-table>
      </div>
    </card>
  </div>
</template>

<style>
.v-data-table-header {
  background: #27293d;
}
.v-data-table tr td {
  background: #27293d;
}
</style>

<script>
export default {
  name: "Rating",
  props: { 
    title: String, 
    subTitle: String,
    fileName: String,
    ratingType: String,
    keibajoName: String,
    raceBango: String,
  },
  data: () => ({
    keyColumns: [],
    horses: [],
    headers: [],
    ratings: []
  }),
  mounted() {
    this.init();
  },
  methods: {
    createKeyColumns(){
      if(this.ratingType === 'horse') {
        return [      
          { text: "馬番", value: "umaban" },
          { text: "馬名", value: "bamei" },
        ]
      }
      return [      
        { text: "馬番", value: "umaban" },
        { text: "馬名", value: "bamei" },
        {text:"騎手名", value: "kishumei_ryakusho"}
      ]
    },

    createMainShisu(courseType, keibajoCode, turn) {
      let columns = this.createKeyColumns();

      columns = columns.concat([
        { text: "総合", value: "sogo_rating", align: "end" },
        {
          text: "開催競馬場",
          value: `${courseType}_keibajo_${Number(keibajoCode)}_rating`,
          align: "end"
        },
        {
          text: "開催トラック(芝/ダート)",
          value: `${courseType}_rating`,
          align: "end"
        },
        {
          text: "開催周回方向(右/左)",
          value: `${courseType}_${turn}_rating`,
          align: "end"
        },
        { text: "良(馬場)", value: `${courseType}_ryo_rating`, align: "end" },
        {
          text: "稍重(馬場)",
          value: `${courseType}_yayaomo_rating`,
          align: "end"
        },
        { text: "重(馬場)", value: `${courseType}_omo_rating`, align: "end" },
        {
          text: "不良(馬場)",
          value: `${courseType}_furyo_rating`,
          align: "end"
        },
        {
          text: "短距離",
          value: `${courseType}_tankyori_rating`,
          align: "end"
        },
        { text: "マイル", value: `${courseType}_mile_rating`, align: "end" },
        {
          text: "中距離",
          value: `${courseType}_chukyori_rating`,
          align: "end"
        },
        {
          text: "中長距離",
          value: `${courseType}_chu_cho_kyori_rating`,
          align: "end"
        },
        {
          text: "長距離",
          value: `${courseType}_cho_kyori_rating`,
          align: "end"
        }
      ]);

      if(this.ratingType === 'horse') {
        columns = columns.concat([ 
          { text: "馬単体の勝率", value: "sogo_win_rate", align: "end" },
          { text: "騎手を含めた勝率", value: "sogo_team_win_rate", align: "end" },
        ])
      }
      if(this.ratingType === 'jockey') {
        columns = columns.concat([ 
          { text: "馬を含めた勝率", value: "sogo_win_rate", align: "end" },
          // TODO: 騎手同士のレーティングで勝率を出してないので出したい
          // { text: "騎手単体の勝率", value: "sogo_team_win_rate", align: "end" },
        ])
      }
      return columns;
    },
    init() {
      this.horses = JSON.parse(JSON.stringify(require(`@/data/${this.keibajoName}-${Number(this.raceBango)}/${this.fileName}`)))
      const winRateSum = {};
      for (const horse of this.horses) {
        for (const key of Object.keys(horse)) {
          if (key.endsWith("win_rate")) {
            if (!winRateSum[key]) {
              winRateSum[key] = 0;
            }
            if (horse[key] != null) {
               winRateSum[key] = Number(winRateSum[key]) + Number(horse[key]);
            } 
          }
        }
      }

      for (const horse of this.horses) {
        for (const key of Object.keys(horse)) {
          const score = !horse[key] ? 0 : horse[key];
          if (key.endsWith("rating")) {
            horse[key] = Number(score).toFixed(3);
          }

          if (key.endsWith("win_rate")) {
            if (winRateSum[key] === 0) {
              horse[key] = "00.00%";
            } else {
              const percent = ((score / winRateSum[key]) * 100).toFixed(2);
              horse[key] = percent.padStart(5, "0") + "%";
            }
          }
        }
      }

      const keibajoCode = this.horses[0].keibajo_code;
      const courseType = this.horses[0].course_type;
      const turn = this.horses[0].turn === "右" ? "migi" : "hidari";
      this.headers = this.createMainShisu(courseType, keibajoCode, turn);
    }
  }
};
</script>
