<script>
import { Scatter } from "vue-chartjs";

export default {
  extends: Scatter,
  name: "chart",
  props: {
    title: String,
    subTitle: String,
    fileName: String,
    ratingType: String,
    keibajoName: String,
    raceBango: String
  },
  data() {
    return {
      data: {
        datasets: []
      },
      options: {
        legend: {
          labels: {
            fontColor: "rgba(255, 255, 255, 1)"
          }
        },
        tooltips: {
          // 吹き出しの背景色
          // backgroundColor: "rgba(19, 56, 95, 0.9)",
          // 吹き出し内の余白
          // xPadding: 12,
          // yPadding: 10,
          // マーカーによって変更するもの
          callbacks: {
            // ツールチップの表示内容
            label: function(tooltipItem, data) {
              // グループ名
                var groupName = data.datasets[tooltipItem.datasetIndex].label;
                // // X 軸ラベル
                // var xAxesLabel = this.options.scales.xAxes[0].scaleLabel.labelString;
                // // y 軸ラベル
                // var yAxesLabel = this.options.scales.yAxes[0].scaleLabel.labelString;

                // 表示するテキスト
                // x の値が tooltipItem.label
                // y の値が tooltipItem.value
                return ` ${groupName}: ${100 - Number(tooltipItem.label).toFixed(2)}%`;
            }
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                min: 0,
                max: 100
              },
              scaleLabel: {
                display: true,
                labelString: "想定最終位置"
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                max: 2
              }
            }
          ]
        }
      }
    };
  },
  mounted() {
    this.horses = JSON.parse(
      JSON.stringify(
        require(`@/data/${this.keibajoName}-${Number(this.raceBango)}/${this.fileName}`)
      )
    );
    const colors = [
      "rgba(0, 0, 0, 1)",
      "rgba(255, 0, 0, 1)",
      "rgba(0, 255, 0, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(255, 0, 255, 1)",
      "rgba(0, 255, 255, 1)",
      "rgba(255, 255, 255, 1)",
      "rgba(128, 0, 0, 1)",
      "rgba(0, 128, 0, 1)",
      "rgba(128, 128, 0, 1)",
      "rgba(0, 0, 128, 1)",
      "rgba(128, 0, 128, 1)",
      "rgba(0, 128, 128, 1)",
      "rgba(128, 128, 128, 1)",
      "rgba(64, 0, 0, 1)",
      "rgba(0, 64, 0, 1)",
      "rgba(64, 64, 0, 1)",
      "rgba(0, 0, 64, 1)",
      "rgba(64, 0, 64, 1)",
      "rgba(0, 64, 64, 1)",
      "rgba(64, 64, 64, 1)"
    ];
    const maxUmaban = Math.max(...this.horses.map(data => Number(data.umaban)));
    const minChakujun = Math.min(...this.horses.map(data => Number(data.tansho_score)));
    const maxChakujun = Math.max(...this.horses.map(data => Number(data.tansho_score)));
    // const per = (maxChakujun-minChakujun)/(maxUmaban-1);
    // this.options.scales.xAxes[0].ticks.max = 100; //maxUmaban;
    this.data.datasets = this.horses.map(data => {
      return {
        label: data.bamei,
        data: [
          {
            x: Number(data.tansho_score),
            y: 0.2 + data.umaban / 18
          }
        ],
        backgroundColor: colors[parseInt(Number(data.umaban))],
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 0.5,
        // fill: false,
        pointRadius: 5
      };
    });
    this.renderChart(this.data, this.options);
  }
};
</script>
