<template>
  <v-container class="ma-0 text-left" >
      1着スコアの分散:{{scattering.toFixed(2)}}
      <v-data-table dark mobile-breakpoint="0" :headers="headers" :items="horses" :items-per-page="100000" :hide-default-footer="true" class="ma-10 text-left" :sort-by="['tansho_score']" :sort-desc="[true]">

        <template v-slot:[`item.color`]="{ value }" style="padding:0;margin:0;">
          <div :style="`background-color:${value};padding:0;margin:0;`">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </template>
      </v-data-table>
      <div v-if="ratings.length == 0" dark style="color:white;">このレースの予想はまだ公開されていません。おおよそレースの20分前頃に公開されます。</div>
  </v-container>
</template>
<style>
.v-data-table-header {
    background: #27293d;
}
.v-data-table tr td {
    background: #27293d;
}
</style>
<script>
export default {
  name: "Rating",
  props: {
    keibajoName: String,
    raceBango: String,
    fileName: String
  },
  data: () => ({
    horses: [],
    headers: [],
    ratings: [],
    scattering: 0,
  }),
  methods: {
    init() {
      // if(this.ratings[0]["is_error"]) {
      //   return;
      // }
      this.horses = JSON.parse(JSON.stringify(this.ratings));
      const COLOR = {
          "lt8" :["white","black","red","blue","yellow","green","orange","pink"],
          9 :["white","black","red","blue","yellow","green","orange","pink","pink"],
          10 :["white","black","red","blue","yellow","green","orange","orange","pink","pink"],
          11 :["white","black","red","blue","yellow","green","green","orange","orange","pink","pink"],
          12 :["white","black","red","blue","yellow","yellow","green","green","orange","orange","pink","pink"],
          13 :["white","black","red","blue","blue","yellow","yellow","green","green","orange","orange","pink","pink"],
          14 :["white","black","red","red","blue","blue","yellow","yellow","green","green","orange","orange","pink","pink"],
          15 :["white","black","black","red","red","blue","blue","yellow","yellow","green","green","orange","orange","pink","pink"],
          16 :["white","white","black","black","red","red","blue","blue","yellow","yellow","green","green","orange","orange","pink","pink"],
          17 :["white","white","black","black","red","red","blue","blue","yellow","yellow","green","green","orange","orange","pink","pink","pink",],
          18 :["white","white","black","black","red","red","blue","blue","yellow","yellow","green","green","orange","orange","orange","pink","pink","pink",],
      };
      const shussoTosu = Number(this.horses.length);
      let gateColor = COLOR["lt8"];
      if(shussoTosu > 8) {
        gateColor = COLOR[shussoTosu];
      }
      this.horses = this.horses.sort((d1,d2) => {
        return Number(d2.tansho_score) - Number(d1.tansho_score);
      });

      let scoreRank = 1;
      let totalScore = 0.0;
      for (const horse of this.horses) {
        horse.color = gateColor[Number(horse.umaban)-1]
        if(horse.ninki > scoreRank) {
          horse.scoreRank = "☆"
        }
        horse.tansho_score = (Number(horse.tansho_score).toFixed(2)+"").padStart(5, "0");
        totalScore+=Number(horse.tansho_score);
        horse.score = Number(horse.score).toFixed(2)
        horse.shouritsu = Number(Number(horse.shouritsu)*100).toFixed(2)
        horse.score1 = Number(horse.score1).toFixed(2)
        horse.score2 = Number(horse.score2).toFixed(2)
        horse.score3 = Number(horse.score3).toFixed(2)
        horse.score4 = Number(horse.score4).toFixed(2)
        horse.score5 = Number(horse.score5).toFixed(2)
        // horse.adv = Number(horse.ninki) -
        horse.reg = Number(horse.reg).toFixed(3)
        scoreRank+=1;
        if (horse.class === "1") {
          horse.class = "01_軸候補";
          continue;
        }
        if (horse.class === "0") {
          horse.class = "02_相手候補";
          continue;
        }
        if (horse.class === "2") {
          horse.class = "03_対抗候補";
          continue;
        }
        horse.class = "04_穴候補";
      }
      let average = totalScore / shussoTosu;
      // 分散
      let scattering = 0;
      for (const horse of this.horses) {
        let diff =  horse.tansho_score - average;
        scattering += (diff * diff)
      }
      // 分散
      scattering = scattering / shussoTosu;
      this.scattering = scattering;
      let std = Math.sqrt(scattering);
      for (const horse of this.horses) {
        let diff = horse.tansho_score - average;
        horse.std =  (((diff * 10) / std) + 50).toFixed(2);

      }
      this.headers = [
        // { text: "枠番", value: "wakuban" },
        { text: "枠色", value: "color" ,width: 50 },
        { text: "馬番", value: "umaban" ,width: 50 },
        { text: "馬名", value: "bamei" ,align:"end",width: 250},
        { text: "1着スコア", value: "tansho_score" ,align:"end",width: 100},
        // { text: "補正勝率", value: "shouritsu" ,align:"end",width: 100},
        { text: "推定勝率(β版)", value: "shouritsu" ,align:"end",width: 100},
        { text: "1着スコア偏差値", value: "std" ,align:"end",width: 100},
        // { text: "3位以内スコア", value: "score" ,align:"end",width: 100},
        // { text: "3位以内スコア1", value: "score1" ,align:"end",width: 100},
        // { text: "3位以内スコア2", value: "score2" ,align:"end",width: 100},
        // { text: "3位以内スコア3", value: "score3" ,align:"end",width: 100},
        // { text: "3位以内スコア4", value: "score4" ,align:"end",width: 100},
        // { text: "3位以内スコア5", value: "score5" ,align:"end",width: 100},
        // { text: "予想グループ", value:"class" ,align:"end",width: 200},
        { text: "予想時点での人気", value: "ninki" ,align:"end",width: 100},
        { text: "予想時点で人気薄", value: "scoreRank" ,align:"end",width: 100},
        // { text: "結果", value: "chakujun" ,align:"end",width: 100}
      ];
    }
  },
  async mounted() {
    // http://localhost:8080/dashboard?date=20220424&keibajo_name=tokyo&race_number=1
    try {
      const keibajo_code_map = {"sapporo": "01","hakodate": "02","fukushima": "03","niigata": "04","tokyo": "05","nakayama": "06","chukyo": "07","kyoto": "08","hanshin": "09","kokura": "10","monbetsu": "30","morioka": "35","mizusawa": "36","ooi": "44","kawasaki": "45","urawa": "42","funabashi": "43","kanazawa": "46","kasamatsu": "47","nagoya": "48","sonoda": "50","himeji": "51","kouchi": "54","saga": "55"}
      const keibajo_code = keibajo_code_map[this.keibajoName];
      const race_bango = this.raceBango;
      const file_name = this.fileName;
      const url=`https://sqmprokrqwomyfydfvpq.supabase.co/storage/v1/object/public/keiba-util-predict/${process.env["VUE_APP_KAISAI_DATE"]}/${keibajo_code}/${race_bango}/${file_name}`;
      const response = await fetch(url);
      this.ratings = await response.json();
    } catch (ex) {
      this.ratings = []
    }
      this.init();
  },
  watch: {
    ratings() {
      this.init();
    }
  }
};
</script>
